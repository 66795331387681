@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import './tailwind.css';

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  @font-face {
    font-family: "Palatino Linotype";
    font-weight: 400;
    src: url(./fonts/pala.ttf);
  }

    @font-face {
      font-family: "Palatino Linotype";
      font-weight: 700;
      src: url(./fonts/palab.ttf);
    }
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

html,
body {
  font-family: var(--font-body);
  font-weight: 400;
  line-height: 160%;
  letter-spacing: 0px;
  text-align: center;

  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary);
  color: var(--text-primary);
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  outline: 0;
  min-height: 100%;
  transition: 0.3s;
  z-index: 0;
}